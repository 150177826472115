import type Employee from '@/model/Employee'
import type { CompanyEmployee } from '@/model/Employee'
import { defineStore } from 'pinia'

const CURRENT_ACCOUNT_ID_KEY = 'currentAccountId'

export const useProfileStore = defineStore('profile-store', {
  state: () => ({
    // authUserToken: '' as string,
    profile: undefined as Employee | undefined,
    selectedCompany: {} as CompanyEmployee,
    availableCompanies: [] as CompanyEmployee[]
  }),
  getters: {
    getProfile: (applicationState: any) => applicationState.profile,
    getSelectedCompany: (applicationState: any) => applicationState.selectedCompany,
    getAvailableCompanies: (applicationState: any) => applicationState.availableCompanies
  },
  actions: {
    async saveProfile(employee: Employee) {
      this.profile = employee

      this.availableCompanies = employee.companies

      const companyId = localStorage.getItem(CURRENT_ACCOUNT_ID_KEY)

      if (!companyId) {
        this.setSelectedCompany(employee.companies[0])
      } else {
        const companyFound = employee.companies.find(
          (company: CompanyEmployee) => company.id === companyId
        )

        if (companyFound?.id) {
          this.setSelectedCompany(companyFound)
        } else {
          this.setSelectedCompany(employee.companies[0])
        }
      }
    },
    setSelectedCompany(company: CompanyEmployee) {
      localStorage.setItem(CURRENT_ACCOUNT_ID_KEY, company.id)

      this.selectedCompany = company
    }
  }
})
