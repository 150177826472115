<template>
  <div>
    <img v-if="img" :src="imgUrl" alt="" />
    <i v-if="icon" :class="icon" ></i>
    <h2 v-if="heading && secondary">{{ heading }}</h2>
    <h3 v-else-if="heading && !secondary">{{ heading }}</h3>
    <p v-html="text"></p>
    <slot name="bottom"></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    img: {
      type: String,
    },
    icon: {
      type: String,
    },
    heading: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    secondary: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  setup(props) {
    const imgUrl = `${window.location.origin}/assets/images/${props.img}`;
    return { imgUrl };
  },
});
</script>
<style scoped>
div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--space-md) auto;
  max-width: 384px;
}

img {
  margin: var(--space-md) var(--space-md) 0 var(--space-md);
  display: block;
}
i::before {
  color: var(--lavender-400);
  font-size: 2.5rem;
}

h2,
h3 {
  margin: var(--space-sm) var(--space-sm) 0 var(--space-sm);
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--lavender-700);
  text-align: center;
}

p {
  margin: var(--space-sm);
  text-align: center;
  color: var(--grey-500);
}
</style>
